import { defineStore } from "pinia";
import User from "../utilities/User"
import { BACKEND_URL } from '../variables.js'
import axios from 'axios'
import 'regenerator-runtime'
import { datadogRum } from '@datadog/browser-rum'; 
export const useUserStore = defineStore("UserStore", {
    state: () => ({current_user: new User()}),
    actions: {
        setUser(bc_access, first_name, last_name, mail, role,status, uid) {
            this.current_user = new User(bc_access, first_name, last_name, mail, role,status, uid)
        },
        async checkLogin(){
            try {
                let res = await Promise.all([axios.get(BACKEND_URL + 'api/current_user'), axios.get(BACKEND_URL + 'api/get_role')]);
                let loggedIn = false
                console.log(res,'HERE user store')
                res.forEach(x => { 
                    if(x.data.role != "" && x.data.role != undefined)
                    { 
                        if(x.data.status == 1){ 
                            this.setUser(x.data.bcaccess, x.data.first_name, x.data.last_name, x.data.mail, x.data.role, x.data.status, x.data.uid)
                        }else{
                            this.current_user.role = x.data.role 
                        }
                        loggedIn = true  
                    }
                })
                
                datadogRum.init({
                    applicationId: '2f52e0c7-bf33-498b-b9e1-7410a6e15c69',
                    clientToken: 'pubb917244263b18960556578f4cf6512c1',
                    // `site` refers to the Datadog site parameter of your organization
                    // see https://docs.datadoghq.com/getting_started/site/
                    site: 'us5.datadoghq.com',
                    service: 'real-property-data',
                    env: '<ENV_NAME>',
                    // Specify a version number to identify the deployed version of your application in Datadog
                    // version: '1.0.0',
                    sessionSampleRate: 100,
                    sessionReplaySampleRate: 20,
                    trackUserInteractions: true,
                    trackResources: true,
                    trackLongTasks: true,
                    defaultPrivacyLevel: 'mask-user-input',
                }); 

                datadogRum.setUser({
                    id: this.current_user.uid,
                    name: `${this.current_user.first_name} ${this.current_user.last_name}`,
                    email: this.current_user.mail
                })
 
                return loggedIn
            } catch (error) {
                throw error
            }
        },
        
        getUser(){
            return this.current_user
        },
    },
    getters: {
        isLogin(state){
            if(state.current_user.status || state.current_user.status != undefined)
                return true

            return false
        }

    }
}) 