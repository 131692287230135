<template>
    <div>
        <b-table 
        :ref="table_reference"
        responsive="sm"
        hover
        small 
        striped 
        :current-page="currentPage"
        :per-page="perPage"
        thead-class="bg-vb-secondary text-light position-sticky"
        :items="row_data"
        :fields="header_fields"
      >  
        <template #head(checkbox)="data">
            <b-form-group>
                <b-form-checkbox
                dis
                size="lg"
                @change="onSelectAllRows"
                v-model="all_rows_selected"
                variant="danger"
                class="mr-1 ml-2 _red-checkbox"
                >
                </b-form-checkbox>
            </b-form-group>
        </template>
        <template #head(mls_address)="data">
          {{ data.label }}
          <button @click="$emit('refreshResults', listing_type)" class="btn btn-light ml-3">UPDATE TABLE</button>
        </template>  
        <template #cell(checkbox)="data">
          <b-form-group>
            <b-form-checkbox
              size="lg"
              variant="danger" 
              v-model="data.item.rowSelected"
              @change="promptRow(data.item)"
              class="mr-1 _red-checkbox"
            >
            </b-form-checkbox>
          </b-form-group>
        </template>  
        
        <template #cell(mls_address)="data">
          <u
            v-if="data.item.mls_floor_plan_feature"
            class="text-primary"
            v-b-modal="'mls-fp-' + data.item.mls_floor_plan_feature.fid"
            >{{ data.value }}</u
          >
          <span v-else>{{ data.value }}</span>
        </template>
        <template #cell(mls_beds)="data">
          <strong
            class="font-weight-bold"
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            v-if="data.value == 's'"
            >Studio</strong
          >
          <strong
            class="font-weight-bold" 
            v-b-modal="`mls-${listing_type}-` + data.item.cid"
            v-else
            >{{ data.item.mls_beds }}-bed</strong
          >
          <br />
          {{
            data.item.fp_specs.toLowerCase() != "none" ? data.item.fp_specs : ""
          }}
        </template>
        <template #cell(mls_list_price)="rowData">
            <span v-if="rowData.value != '-'"
            v-b-modal="`mls-${listing_type}-` + rowData.item.cid"
            @click="$emit('popHistory', rowData.item)">
                <span :style="{'color' :  setColor(rowData.item.price_back_up)}">{{ `$${Math.round(rowData.value).toLocaleString()}` }}</span>
                <template v-if="Number(getPriceChange(rowData.item.price_second, rowData.value).diffPercentage) != 0">
                  (<span class="font-weight-bold" :style="{'color' : getPriceChange(rowData.item.price_second, rowData.value).color}">{{ getPriceChange(rowData.item.price_second, rowData.value).diffPercentage }}%</span><font-awesome-icon class="font-weight-bold" :icon="Number(rowData.value) > 0 ? ['fas', getPriceChange(rowData.item.price_second, rowData.value).icon ] : ''" />)                
                </template>
            </span>
            <span v-else>-</span>
        </template> 
        <template #cell()="rowData">
            <span v-b-modal="`mls-${listing_type}-` + rowData.item.cid" @click="$emit('popHistory', rowData.item)">
                {{ rowData.value ? rowData.value : '-' }}
            </span>
        </template>
      </b-table>
    </div>
</template>

<script setup lang="ts" > 
import { ref, computed, getCurrentInstance, onMounted, watch  } from "vue";
import Vue from "vue";
import { BModal, VBModal, VBTooltip } from "bootstrap-vue";
import { useRouter } from "vue2-helpers/vue-router";
import { useUserStore } from "../../store/UserStore";
import { BBadge } from "bootstrap-vue";
import {ROOT_URL} from '../../variables.js';

const props = defineProps({
  row_data: [],
  header_fields: [],
  perPage: null,
  currentPage: null,
  totalRows: null,
  listing_type: null,
  table_reference: null
});


const emit = defineEmits([
  "popModal",
  "updateTotalRows", 
  "selectAllRows",
  "popHistory",
  "countProjectSelected",
  "getFilteredData"
]);

const promptRow = (data) => {
  emit("countProjectSelected", data);
};

const all_rows_selected = ref(false);
const onSelectAllRows = () => {
  emit("selectAllRows", {
    selectAll: all_rows_selected.value,
    tableRef: props.table_reference,
  });
};

const setColor = (data) => {
  let color = "";
  if (data) {
    switch (data.toLocaleLowerCase()) {
      case "verified":
        color = "#0b36dc";
        break;

      case "educated estimated":
        color = "#dc3545";
        break;

      case "semi-verified":
        color = "#FF6F16";
        break;

      default:
        color = "#000";
        break;
    }
  }
  return color;
};

const getPriceChange = (oldValue, newValue) => {
  let oldToNum = Number(oldValue)
  let newToNum= Number(newValue)
  // console.log(oldToNum, newValue)
  let result = {
      diffPercentage: '',
      color: '',
      icon: ''
  }

    if(newValue == '-'|| !oldValue)
        return result = {
            diffPercentage: '',
            color: '',
            icon: ''
        }

        result.diffPercentage = Number(((newToNum - oldToNum) / oldToNum)*100).toFixed(2)
    // result.diffPercentage = Number(((Number(newValue) - Number(oldValue)) / Number(oldValue)) * 100).toFixed(2);
    if(Number(result.diffPercentage) > 0) {
        result.color = 'green'
        result.icon = "fa-long-arrow-alt-up"
    } else if (Number(result.diffPercentage) < 0) {
        result.color = 'red'
        result.icon = "fa-long-arrow-alt-down"
    } else {
        result.color = 'black'
        result.icon = "minus"
    }

    
    return result
}
</script>

<style lang="scss" scoped>

</style>