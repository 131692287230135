<template>
    <div>
        <b-table 
        ref="history_table"
        responsive="sm"
        hover
        small 
        striped 
        :current-page="currentPage"
        :per-page="perPage"
        thead-class="bg-vb-secondary text-light position-sticky"
        :items="row_data"
        :fields="header_fields"
      >
        <template #cell(files)="data">
            <span v-for="(item, index) in data.value" :key="index">
                <a target="_blank" :href="item.url" :key="item.index">{{item.index}}</a><span :key="item.index" v-if="item.index < data.value.length">, </span> 
            </span>
        </template>
        
        <template #cell(avg_price)="data">
            {{ ['now leasing','now leasing 2','leased out','leased out 2','now selling','now selling 2','sold out','sold out 2'].includes(props.project_status.toLowerCase()) ? keepDecimalPlace(data.value) : roundToWholeNumber(data.value) }}
            <template v-if="!isNaN(percentageChange(data.item.prev_avg_price, data.value).diffPercentage) && Number(percentageChange(data.item.prev_avg_price, data.value).diffPercentage != 0 && Number(percentageChange(data.item.prev_avg_price, data.value).diffPercentage != null))">
                (<span v-if="!isNaN(percentageChange(data.item.prev_avg_price, data.value).diffPercentage)"
                class="font-weight-bold" :style="{'color' : percentageChange(data.item.prev_avg_price, data.value).color}"
            >{{ percentageChange(data.item.prev_avg_price, data.value).diffPercentage }}%</span>
                <font-awesome-icon class="font-weight-bold" :icon="Number(data.value) > 0 ? ['fas', percentageChange(data.item.prev_avg_price, data.value).icon ] : ''" />)
            </template> 
        </template>
        
        <template #cell(avg_psf)="data"> 
            {{ ['now leasing','now leasing 2','leased out','leased out 2','now selling','now selling 2','sold out','sold out 2'].includes(props.project_status.toLowerCase()) ? keepDecimalPlace(data.value) : roundToWholeNumber(data.value) }}
            <template v-if="!isNaN(percentageChange(data.item.prev_avg_psf, data.value).diffPercentage) && Number(percentageChange(data.item.prev_avg_psf, data.value).diffPercentage != 0) && Number(percentageChange(data.item.prev_avg_psf, data.value).diffPercentage != null)">
                (<span v-if="!isNaN(percentageChange(data.item.prev_avg_psf, data.value).diffPercentage)"
                class="font-weight-bold" :style="{'color' : percentageChange(data.item.prev_avg_psf, data.value).color}"
                >{{ percentageChange(data.item.prev_avg_psf, data.value).diffPercentage }}%</span>
                <font-awesome-icon class="font-weight-bold" :icon="Number(data.value) > 0 ? ['fas', percentageChange(data.item.prev_avg_psf, data.value).icon ] : ''" />)
            </template> 
        </template>
        
        <template #cell(total_net_psf)="data"> 
            {{ ['now leasing','now leasing 2','leased out','leased out 2','now selling','now selling 2','sold out','sold out 2'].includes(props.project_status.toLowerCase()) ? keepDecimalPlace(data.value) : roundToWholeNumber(data.value) }}
            <template v-if="!isNaN(percentageChange(data.item.prev_total_net_psf, data.value).diffPercentage) && Number(percentageChange(data.item.prev_total_net_psf, data.value).diffPercentage != 0) && Number(percentageChange(data.item.prev_total_net_psf, data.value).diffPercentage != null)">
                (<span v-if="!isNaN(percentageChange(data.item.prev_total_net_psf, data.value).diffPercentage)"
                class="font-weight-bold" :style="{'color' : percentageChange(data.item.prev_total_net_psf, data.value).color}"
            >{{ percentageChange(data.item.prev_total_net_psf, data.value).diffPercentage }}%</span>
                <font-awesome-icon class="font-weight-bold" :icon="Number(data.value) > 0 ? ['fas', percentageChange(data.item.prev_total_net_psf, data.value).icon ] : ''" />)
            </template> 
        </template>
        
        <template #cell(current_total_net_price)="data">
                {{ ['now leasing','now leasing 2','leased out','leased out 2','now selling','now selling 2','sold out','sold out 2'].includes(props.project_status.toLowerCase()) ? keepDecimalPlace(data.value) : roundToWholeNumber(data.value) }}
 
            <template v-if="data.value != '-' && data.value != ''">
                (<span v-if="!isNaN(percentageChange(data.item.prev_total_net_price, data.value).diffPercentage)"
                    class="font-weight-bold" :style="{'color' : percentageChange(data.item.prev_total_net_price, data.value).color}"
                >{{ percentageChange(data.item.prev_total_net_price, data.value).diffPercentage }}%</span>
                <font-awesome-icon class="font-weight-bold" :icon="Number(data.value) > 0 ? ['fas', percentageChange(data.item.prev_total_net_price, data.value).icon ] : ''" />)
            </template>
        </template>
        
        <template #cell(active_avg_price)="data">
            {{ roundToWholeNumberNonMonetary(data.value) }}
        </template>
      </b-table>
    </div>
</template>

<script setup lang="ts" > 
import { ref, computed, getCurrentInstance, onMounted  } from "vue";
import Vue from "vue";
import { BModal, VBModal, VBTooltip } from "bootstrap-vue"; 
import { useUserStore } from "../../store/UserStore";
import { BBadge } from "bootstrap-vue";
import {ROOT_URL} from '../../variables.js';

const props = defineProps({
  row_data: [],
  perPage: null,
  currentPage: null,
  totalRows: null,
  listing_type: null, 
  project_status: null
});
const history_table = ref(null);

const current_user = ref(useUserStore().current_user);
const header_fields = ref(null)

const setHeaderFields = () => {
    switch (current_user.value.role) {
        case 'developer':
            header_fields.value = [
                { label: 'Date',key: 'date'},
                { label: 'Total Units',key: 'total'},
                { label: 'Rlsd Units',key: 'rlsd_units'},
                { label: 'Total Sold',key: 'sold_total'},
                { label: 'Avail',key: 'available'},
            ]
        break;
        case 'developerbc':
            header_fields.value = [
                { label: 'Date',key: 'date'},
                { label: 'Total Units',key: 'total'},
                { label: 'Rlsd Units',key: 'rlsd_units'},
                { label: 'Total Sold',key: 'sold_total'},
                { label: 'Avail',key: 'available'},
            ]
        break;
        //admin role
        case 'admin': 
            header_fields.value = [
                { label: 'Date',key: 'date'},
                { label: 'Total Units',key: 'total'},
                { label: 'Rlsd Units',key: 'rlsd_units'},
                { label: 'Total Sold',key: 'sold_total'},
                { label: 'Avail',key: 'available'},
                { label: 'Total Avg Size', key:'avg_size'},
                { label: 'Total Avg Price', key:'avg_price'},
                { label: 'Total $PSF', key:'avg_psf'},
                { label: 'Net Price', key:'current_total_net_price'},
                { label: 'Net $PSF', key:'total_net_psf'},
                { label: 'Files', key:'files'},
            ]
            if(props.project_status != 'Now Selling') { 
                // let toInsert = [
                //     { label: 'Current $PSF', key: 'active_avg_psf', formatter: roundToWholeNumberNonMonetary},
                //     { label: 'Current Avg Size', key: 'active_avg_size', formatter: roundToWholeNumberNonMonetary},
                //     { label: 'Current Avg Price', key: 'active_avg_price'},
                // ]
                // toInsert.forEach((item, index) => { 
                //     header_fields.value.splice(10 + index, 0, item)
                // }) 
            } else if (!['editor','admin'].includes(current_user.value.role)) { 
                let toInsert = [
                    { label: 'Buyer Incentives', key: 'buyer_incentives' } ,
                    { label: 'Realtor Incentives', key: 'realtor_incentives' } ,
                    { label: 'Assignment Fee', key: 'assignment_fee' } ,
                    { label: 'Deposit', key: 'deposit' } ,
                    { label: 'Realtor commission', key: 'realtor_commission' } ,
                ]
                  
                toInsert.forEach((item, index) => {
                    console.log(index, 'index')
                    header_fields.value.splice(10 + index, 0, item)
                })   
            }
        break;
    }
}

const roundToWholeNumber = (value) => {
    return Number(value) ? '$' + Math.round(value).toLocaleString() : '-'
}

const roundToWholeNumberNonMonetary = (value) => {
    return Number(value) ? Math.round(value).toLocaleString() : '-'
}

const keepDecimalPlace = (value) => {
    return Number(value) ? '$' + Number(Number(value).toFixed(2)).toLocaleString() : '-'
}


const percentageChange = (oldValue, newValue) => {
    let result = {
        diffPercentage: null,
        color: null,
        icon: null
    } 
    
    if(!oldValue && newValue)
        result.diffPercentage = 100
    else if (oldValue > 0 && newValue > 0)
        result.diffPercentage = Number(((newValue - oldValue) / oldValue) * 100).toFixed(2);

    if(Number(result.diffPercentage) > 0) {
        result.color = 'green'
        result.icon = "fa-long-arrow-alt-up"
    } else if (Number(result.diffPercentage) < 0) {
        result.color = 'red'
        result.icon = "fa-long-arrow-alt-down"
    } else {
        result.color = 'black'
        result.icon = "minus"
    }
 
    return result
}
onMounted(() => {
    setHeaderFields()
})
</script>

<style lang="scss" scoped>

</style>